(function($){
    /* Handle the sidebar in push menu style */
    $.fn.pushSidebar = function(options) {
        var _sidebar = $(this);

        _sidebar.pushmenu({ button : options.button });

        $(document).on('click', function(event) {
            var target = $(event.target),
                pmCloseBtnClass = 'push-menu__close',
                pmOpenClass = 'pm_open',
                pmOverlayClass = 'pm_overlay';

            if (
                target.not(options.button).length > 0 &&
                target.parent().not(options.button).length > 0 &&
                _sidebar.hasClass(pmOpenClass)
            ) {
                if (
                    target.closest(_sidebar.attr('id')).length !== 1 ||
                    target.hasClass(pmCloseBtnClass) ||
                    target.parent().hasClass(pmCloseBtnClass)
                ) {
                    $('.' + pmOverlayClass).trigger('click');
                }
            }
        });
    };
})(jQuery);
