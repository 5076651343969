import { getUrlParameter, parseDate } from './utils';

(function ($) {
    /**
     * GLOBAL VARS
     */
    var mqBreakpoints = {
            mobile: 480,
            phablet: 600,
            tablet: 768,
            desktop: 992,
            wide: 1200
        },
        $window = $(window),
        $body = $('body').eq(0),
        windowWidth = $window.innerWidth(),
        now = new Date();


    /**
     * Smoothscroll
     */
    var smoothScroll = function (the_id) {
        $('html, body').animate({
            scrollTop: $(the_id).offset().top - $('header.header[role="banner"]').height() - 30
        }, '350');
    };


    /**
     * Function that get parameter value
     * http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
     */
    var getParameterByName = function (name) {
        name = name.replace(/[\[\]]/g, "\\$&");

        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            url = window.location.href,
            results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };


    var handleExpandedAria = function (current) {
        // $body.find('[aria-expanded="true"]').not(current).trigger('click');
        var closed = [];

        $body.find('[aria-expanded="true"]').not(current).each(function (i, e) {
            var $that = $(e);
            if (0 > $.inArray($that.attr('aria-controls'), closed)) {
                closed.push($that.attr('aria-controls'));
                $that.trigger('click');
            }
        });
    };

    /**
     *  Search
     */
    var searchForm = function () {
        $body.on('click', '.js-trigger-search', function () {
            var $_this = $(this),
                $_triggers = $('[aria-controls="' + $_this.attr('aria-controls') + '"]'),
                $_targetEl = $('#' + $_this.attr('aria-controls')),
                state = $_this.attr('aria-expanded') !== 'false';

            if (!state) {
                $.magnificPopup.instance.close();
                handleExpandedAria($_triggers.toArray());
            }
            $_triggers.attr('aria-expanded', !state);
            $_targetEl.attr('aria-hidden', state);
            $body.toggleClass('has-search', !state);
            $('.search-form__input').focus();
        });
    };


    /**
     *  Menu on mobile
     */
    var mobileMenu = function () {
        $('.js-trigger-menu').on('click', function () {
            var $_this = $(this),
                $_triggers = $('[aria-controls="' + $_this.attr('aria-controls') + '"]'),
                $_targetEl = $('#' + $_this.attr('aria-controls')),
                state = $_this.attr('aria-expanded') !== 'false';

            if (!state) {
                handleExpandedAria($_triggers.toArray());
            }
            $_triggers.attr('aria-expanded', !state);
            $_targetEl.attr('aria-hidden', state);

            $body.toggleClass('has-main-menu', !state);
        });
    };


    /**
     * Check visibility for ARIA controlled element and add attributes (hidden and expanded)
     */
    var checkVisibility = function (id, breakpoint) {
        var $_el = $('#' + id),
            controller = $('[aria-controls=' + id + ']'),
            windowWidth = $window.width();

        if (windowWidth <= breakpoint) {
            $_el.attr('aria-hidden', true);
            controller.attr('aria-expanded', false);
        } else {
            $_el.attr('aria-hidden', false);
            controller.attr('aria-expanded', true);
            $body.removeClass('has-main-menu');
        }
    };


    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    var getMobileOperatingSystem = function () {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    };


    /**
     * GET iOS version
     */
    function iOSVersion() {
        if (window.MSStream) {
            // There is some iOS in Windows Phone...
            // https://msdn.microsoft.com/en-us/library/hh869301(v=vs.85).aspx
            return false;
        }
        var match = (navigator.appVersion).match(/OS (\d+)(\d+)?(\d+)?/),
            version;
        if (match !== undefined && match !== null) {
            version = [
                parseInt(match[1], 10),
                parseInt(match[2], 10),
                parseInt(match[3] || 0, 10)
            ];
            return parseFloat(version.join('.'));
        }
        return false;
    }


    /**
     * LOAD HERO BANNER VIDEO
     */
    var heroBannerVideo = function () {
        var $_heroVideo = $('.js-hero-video');

        if (0 == $_heroVideo.length) {
            return;
        }

        var videoSrc = $_heroVideo.attr('data-video');

        if (windowWidth >= mqBreakpoints.tablet) {
            $_heroVideo.replaceWith('<video autoplay muted loop class="hero-banner__video" src=' + videoSrc + '></video>');
            $_heroVideo = $('.hero-banner__video')[0];

            $(window).blur(function () {
                $_heroVideo.pause();
            });

            $(window).focus(function () {
                $_heroVideo.play();
                $($_heroVideo).attr('muted', true);
            });


            /* IF IOS */
            if (iOSVersion() != false) {
                var playBtn = $('.hero-banner__play');

                playBtn.show();
                playBtn.on('click', function () {
                    $_heroVideo.play();
                });
            }
        }

    };

    var movieFilters = function () {
        // $('.movie-filters').find('button.js-listing-filter').each(function (idx, elt) {
        //     var $that = $(elt);
        //     if ($that.data('filter') != 'all' && $($that.data('target') + '[data-filter*="' + $that.data('filter') + '"]').length == 0) {
        //         $('.movie-filters').find('option[value="' + $that.data('filter') + '"]').prop("disabled", "disabled");
        //         $that.parent().hide();
        //     }
        // });
        // if ($('.movie-filters').find('select.js-listing-filter option:enabled').length < 3) {
        //     $('.movie-filters').hide();
        // }
    };

    var movieSentence = function () {
        $('.cine-card__next-show .sentence').hide();
        $('.cine-card__next-show').each(function (index, element) {
            if ("" == $(element).data('shows')) {
                $(element).find('.no_more').show();
                return;
            }

            var shows = $(element).data('shows').replace(/,$/, '').split(',');
            $.each(shows, function (idx, value) {
                if ("" == value) {
                    return;
                }

                var showDate = parseDate(value);
                if (showDate >= now) {
                    var timeDiff = Math.abs(showDate.getTime() - now.getTime());
                    var diffDays = Math.floor(timeDiff / 86400000); // 1000 * 60 * 60 * 24
                    var diffHours = Math.floor(timeDiff / 3600000); // 1000 * 60 * 60

                    if (diffDays == 0) {
                        if (diffHours < 1) {
                            var sentence = $(element).find('.less_one_hour');
                            sentence.html(sentence.html().replace("{{duration}}", Math.ceil(timeDiff / 60000)));
                            sentence.show();
                        } else {
                            if (showDate.getDay() == now.getDay()) {
                                var sentence = $(element).find('.today');
                                sentence.html(sentence.html().replace("{{duration}}", diffHours + 1));
                                sentence.show();
                            } else {
                                $(element).find('.tomorrow').show();
                            }
                        }
                    } else {
                        var sentence = $(element).find('.next_day'),
                            val;
                        try {
                            val = new Intl.DateTimeFormat($('html').attr('lang'), {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit"
                            }).format(showDate);
                        } catch (err) {
                            // Maybe we could use: https://github.com/andyearnshaw/Intl.js/
                            val = value;
                        }
                        sentence.html(sentence.html().replace("{{duration}}", val));
                        sentence.show();
                    }

                    return false; // Only to break the loop
                }

                if (idx == shows.length) {
                    $(element).find('.no_more').show();
                }
            });
        });
    };


    var birthdatePicker = function () {
        $('.js-birthdate').on('change', 'select', function (e) {
            var that = $(e.delegateTarget),
                day = that.find('select[name="day"]'),
                month = that.find('select[name="month"]'),
                year = that.find('select[name="year"]'),
                field = that.find('input[type="hidden"]');

            field.val(year.val() + '-' + month.val() + '-' + day.val());
        });
    };

    var dynamicDatePicker = function () {
        $('.js-dynamicDate').on('change', 'select', function (e) {

            var that = $(e.delegateTarget),
                itemName = that.data('form'),
                day = that.find('select[name="'+ itemName +'-day"]'),
                month = that.find('select[name="'+ itemName +'-month"]'),
                year = that.find('select[name="'+ itemName +'-year"]'),
                inputDay = that.find('input[id="'+ itemName +'-day"]'),
                inputMonth = that.find('input[id="'+ itemName +'-month"]'),
                inputYear = that.find('input[id="'+ itemName +'-year"]');

            inputDay.val(parseInt(day.val(),10));
            inputMonth.val(parseInt(month.val(),10));
            inputYear.val(parseInt(year.val(),10));
        });
    };


    var loyaltyDisplay = function() {
        var $elem = $('.js-loyalty-display [name="loyaltyCard"]');
        if (!$elem.length) {
            return;
        }

        $elem.on('change', function() {
            var val = $(this).val();
            if (val === '1') {
                $('.js-loyalty-display-card').show();
                $('.js-loyalty-display-register').hide();
            } else if (val === '0') {
                $('.js-loyalty-display-card').hide();
                $('.js-loyalty-display-register').show();
            }
        });

        $('.js-loyalty-display-card').hide();
        $('.js-loyalty-display-register').hide();
    };

    var giftcardDisplay = function() {
        var $elem = $('.js-giftcard-display [name="giftCard"]');
        if (!$elem.length) {
            return;
        }

        $elem.on('change', function() {
            var val = $(this).val();
            if (val === '1') {
                $('.js-giftcard-display-card').show();
            } else {
                $('.js-giftcard-display-card').hide();
            }
        });

        $('.js-giftcard-display-card').hide();
    };

    var moveLanguageSwitcher = function () {
        var supportMenu = $('#main-nav').find('.support-menu'),
            languageSwitcher = $('#main-nav').find('.language-switcher');

        if (supportMenu.length && languageSwitcher.length) {
            supportMenu.append($('<li>').append(languageSwitcher));
            languageSwitcher.show();
        }
    };

    var moveOpeningInMenu = function () {
        var supportMenu = $('#main-nav').find('.support-menu'),
            openingInMenu = $('#main-nav').find('.opening-in-menu');

        if (supportMenu.length && openingInMenu.length) {
            supportMenu.prepend($('<li>').append(openingInMenu));
            openingInMenu.show();
        }
    };


    var contactSubjectOther = function () {
        var $other = $('.js-other');
        var $otherPrefix = $other.data('other-prefix') || 'Other:';

        if (!$other.length > 0) {
            return;
        }

        var $others = $('input[name="' + $other.attr('name') + '"]'),
            $text = $other.siblings('input[type="text"]');

        function toggleTextField(enabled) {
            $text.attr('disabled', !enabled)
                .attr('required', enabled);
            if (enabled) {
                $text.focus();
            }
        }

        toggleTextField($other.is(':checked'));
        $others.on('change', function() {
            toggleTextField($other.is(':checked'));
        });

        $text.on('change paste keyup', function() {
            $other.val($otherPrefix + ' ' + $text.val());
        });
    };

    var foodcourtCarousel = function() {
        var carousel = $('.foodcourt-hours__carousel');

        if (!carousel.length) {
            return;
        }

        carousel.slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 5,
            responsive: [
                {
                    breakpoint: mqBreakpoints.tablet,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: mqBreakpoints.mobile,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    };

    var sidebarFc = function (options, onSuccess) {
        this.options = {
            'child_node': ' > li.sidebar__item',
            'active_item': null
        };

        /** Init the sidebar */
        this.init = function () {
            this.options = $.extend(this.options, options);
            this.sidebar = $(this);
            this.sidebarItems = this.sidebar.find(this.options.child_node);

            this.sidebarItems.each(function(key, item) {
                $(item).on('click', this.itemSelection.bind(this, item));

                if (null !== this.options.active_item && this.options.active_item === $(item).data('sidebar-item')) {
                    $(item).trigger('click');
                }
            }.bind(this));

            if (!this.options.active_item) {
                this.sidebarItems.first().trigger('click');
            }

            this.sidebar.find('.dropdown-placeholder').each(function(key, placeholder) {
                $(placeholder).on('click', function () {
                    this.sidebarItems.each(function(key, item) {
                        $(item).slideToggle();
                    });
                }.bind(this));
            }.bind(this));

            $(window).on('resize', function () {
                this.sidebarItems.each(function(key, item) {
                    $(window).width() <= 767 ? $(item).hide() : $(item).show();
                });
            }.bind(this));
        };

        /** action to process when a sidebar item is clicked **/
        this.itemSelection = function (item) {
            this.options.active_item = $(item).data('sidebar-item');

            this.sidebarItems.removeClass('active');
            $(item).addClass('active');

            $('[data-sidebar-display] [data-sidebar-item]').removeClass('active');
            $('[data-sidebar-display="' + this.sidebar.data('sidebar') + '"] [data-sidebar-item="' + this.options.active_item + '"]').addClass('active');

            if ($(window).width() <= 767) {
                this.sidebarItems.each(function(key, item) {
                    $(item).slideUp();
                });
            }
        };

        this.init();

        if (onSuccess) {
            onSuccess.call();
        }
    };

    $.fn.extend({ sidebar: sidebarFc });

    var gallerySlider = function() {
        var carousel = $('.js-gallery-slider');

        if (!carousel.length) {
            return;
        }

        var slides = ((typeof carousel.data('slides') !== 'undefined') ? carousel.data('slides') : 2);

        carousel.slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: slides,
            slidesToScroll: slides,
            autoplay: true,
            responsive: [
                {
                    breakpoint: mqBreakpoints.mobile,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    };

    var cultureSlider = function() {
        var carousel = $('.js-culture-carousel');

        if (!carousel.length) {
            return;
        }

        var slides = ((typeof carousel.data('slides') !== 'undefined') ? carousel.data('slides') : 2);

        carousel.slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: slides,
            slidesToScroll: slides,
            autoplay: true,
            responsive: [
                {
                    breakpoint: mqBreakpoints.tablet,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    };

    $(document).ready(function () {
        contactSubjectOther();
        searchForm();
        moveLanguageSwitcher();
        moveOpeningInMenu();
        birthdatePicker();
        dynamicDatePicker();
        movieFilters();
        movieSentence();
        mobileMenu();
        checkVisibility('main-nav', mqBreakpoints.desktop);
        $('.underpanel').underPanel();
        foodcourtCarousel();
        gallerySlider();
        cultureSlider();
        loyaltyDisplay();
        giftcardDisplay();

        // Sidebar
        $('ul.js-sidebar').sidebar({
            active_item: window.location.hash.replace('#', '')
        });

        // Slide contents
        $('.js-slide-content > *').each(function() {
            var slideContent = $(this).attr('data-slide-content');
            var slideHead = $(this).attr('data-slide-head');

            if (typeof slideContent !== typeof undefined && slideContent !== false) {
                $(this).hide();
            } else if (typeof slideHead !== typeof undefined && slideHead !== false) {
                $(this).on('click', function() {
                    var contentsToHide = $('[data-slide-content][data-slide-content!="' + slideHead + '"]');
                    var activeContent = $('[data-slide-content="' + slideHead + '"]');
                    var activeSVGPicto = $('[data-slide-head="' + slideHead + '"] svg.dropdown-icon');

                    activeContent.stop(true, true).slideToggle();
                    activeSVGPicto.toggleClass('reverse', '');

                    // Hide all the others contents if they are visible
                    if (contentsToHide.is(':visible')) {
                        contentsToHide.stop(true, true).slideUp();
                        $('[data-slide-head][data-slide-head!="' + slideHead + '"] svg.dropdown-icon').removeClass('reverse');
                    }
                });
            }
        });

        /* Gallery */
        $('.js-gallery').magnificPopup({
            delegate: '.js-gallery__item',
            type: 'image',
            gallery: {
                enabled: true
            }
        });


        /* Modal */
        $('.js-modal').magnificPopup({
            type: 'inline'
        });

        $('.js-modal-map').magnificPopup({
            type: 'iframe'
        });

        $('.js-live-content-gallery').magnificPopup({
            delegate: 'a.js-lightbox',
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: false,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            image: {
                verticalFit: true,
                titleSrc: function(item) {
                    return $(item.el).closest('.live-content').find('.live-content__store a').clone();
                    // return item.el.text + ' &middot; <a class="image-source-link" href="'+item.el.attr('data-source')+'" target="_blank">image source</a>';
                }
            },
            gallery: {
                enabled: true
            },
            zoom: {
                enabled: true,
                duration: 300, // don't foget to change the duration also in CSS
                opener: function(element) {
                    return element.find('img');
                }
            },
            callbacks: {
                elementParse: function(item) {
                    if(item.el.context.className.indexOf('-video') !== -1) {
                        item.type = 'inline';
                    } else {
                        item.type = 'image';
                    }
                }
            }

        });

        /* Smoothscroll*/
        $('.js-smoothscroll').on('click', function (e) {
            var url = $(this).attr('href');
            var hash = url.split('#').pop();

            smoothScroll('#' + hash);
            e.preventDefault();
        });


        /* Filters restaurants */
        var restaurantsFilters = function (_restaurantType, _restaurantHours) {
            var $_restaurantList = $('#restaurants-list'),
                _restaurantItems = $_restaurantList.find('li');

            _restaurantItems.each(function () {
                var $_this = $(this),
                    _typeVal = $_this.attr('data-type'),
                    _hoursVal = $_this.attr('data-hours'),
                    _hasType = false,
                    _hasHours = false;

                if (_typeVal.indexOf(_restaurantType) != -1 || _restaurantType == '0') {
                    _hasType = true;
                }

                if (_hoursVal.indexOf(_restaurantHours) != -1 || _restaurantHours == '0') {
                    _hasHours = true;
                }


                if (_hasType && _hasHours) {
                    $_this.attr('aria-hidden', false);
                } else {
                    $_this.attr('aria-hidden', true);
                }
            });
        };

        $('.js-filter-list').on('change', function () {
            var _restaurantType = $('.restaurants-search__type').val(),
                _restaurantHours = $('.restaurants-search__hours').val();

            restaurantsFilters(_restaurantType, _restaurantHours);
        });

        var listingFilter = function (target, data) {
            var $_target = $(target);

            $_target.each(function () {
                var $_this = $(this),
                    _filter = $_this.attr('data-filter');

                if (_filter.indexOf(data) != -1 || data == 'all') {
                    $_this.attr('aria-hidden', false);
                } else {
                    $_this.attr('aria-hidden', true);
                }
            });
        };

        $('button.js-listing-filter').on('click', function () {
            var _filter = $(this).attr('data-filter');

            $('button.js-listing-filter.active').removeClass('active');
            $(this).addClass('active');
            $('select.js-listing-filter').val(_filter);

            listingFilter($(this).data('target'), _filter);
        });

        $('select.js-listing-filter').on('change', function () {
            var _filter = $(this).val();
            $('button.js-listing-filter[data-filter="' + _filter + '"]').trigger('click');
        });


        /* Filters movie hours */
        var movieHoursFilters = function (date) {
            var $_hoursList = $('.movie-details__hours');

            $_hoursList.each(function () {
                var $_this = $(this),
                    _date = $_this.attr('data-date');

                if (_date.indexOf(date) != -1) {
                    $_this.attr('aria-hidden', false);
                } else {
                    $_this.attr('aria-hidden', true);
                }
            });
        };

        $('.js-filter-movie-hours').on('change', function () {
            var _date = $(this).val();
            movieHoursFilters(_date);
        });

        /**
         * Autocomplete search in schedule page
         */
        $('.hours-block .js-autocomplete').each(function () {
            var lookup = eval($(this).data('list'));
            if (typeof lookup != 'undefined') {
                $(this).autocomplete({
                    showNoSuggestionNotice: true,
                    lookup: lookup,
                    onSelect: function (suggestion) {
                        var $_form = $(this).parents('form');

                        $_form.attr('action', suggestion.data);
                        $_form.submit();
                    }
                });
            }
        });
 
        if (typeof mapplic_options !== 'undefined') {
            var mapplic = $('#mapplic').mapplic(mapplic_options).data('mapplic');
            $('#mapplic').trigger('ready');

            if (typeof mapplic_options['location'] !== 'undefined') {
                mapplic.showLocation(mapplic_options['location']);
            }
        }

        /**
         * Tabs
         */
        $('.js-tabs').tabs({
            updateHash: true
        });


        /**
         * Submit form
         */
        $('.js-ajax-form').ajaxForm({
            dataType: 'json',
            beforeSubmit: function (arr, form, options) {
                form.find('[class*="message--"]').remove();
                form.find('.form-field').removeClass('has-error');

                var spinnerBtn = form.find('[data-spinner]');
                if (spinnerBtn) {
                    spinnerBtn.attr('data-orig-text', spinnerBtn.html());
                    spinnerBtn.html(spinnerBtn.attr('data-spinner'));
                    spinnerBtn.attr('disabled', true);
                }
            },
            success: function (responseText, statusText, xhr, form) {
                var spinnerBtn = form.find('[data-spinner]');
                if (spinnerBtn) {
                    spinnerBtn.html(spinnerBtn.attr('data-orig-text'));
                    spinnerBtn.removeAttr('disabled');
                }

                let response = eval(responseText);

                let illustration = '/build/images/undraw/check02_thumb.png',
                    message_success_exist = (((response || {}).fallback || {}).messages || {}).success,
                    message_already_exist = (((response || {}).fallback || {}).messages || {}).already;

                let successMessage = message_success_exist ? response.fallback.messages.success : form.attr('data-success')  ,
                    redirectPath = form.attr('data-redirect')
                ;

                if (redirectPath && (201 === xhr.status || 200 === xhr.status)) {
                    window.location = Routing.generate('homepage', {slug: redirectPath}, true);
                    return;
                }

                let close_redirect = getUrlParameter('redirect-after-success');
                let confirmationCallback = $(form).sccSuccessConfirmation;

                if (201 != xhr.status) {
                    successMessage = message_already_exist ? response.fallback.messages.already : form.attr('data-already');
                    illustration = '/build/images/undraw/warning01_thumb.png';
                    close_redirect = null;
                    confirmationCallback = $(form).sccWarningConfirmation;
                }

                confirmationCallback(successMessage, {
                    dom_parent: $('body'),
                    background_effect: 'blur',
                    illustration: illustration,
                    auto_remove: Boolean(Number($(form).attr('data-auto_hide_success'))),
                    auto_remove_delay: Boolean(Number($(form).attr('data-auto_hide_success'))) ? 3000 : 0,
                    close_btn_label: $(form).attr('data-btn_label') || 'OK',
                    close_redirect: close_redirect || null,
                });
                form.resetForm();
            },
            error: function (responseText, statusText, xhr, $form) {
                var spinnerBtn = $form.find('[data-spinner]');
                if (spinnerBtn) {
                    spinnerBtn.html(spinnerBtn.attr('data-orig-text'));
                    spinnerBtn.removeAttr('disabled');
                }

                var errorMessage = $form.attr('data-error');

                if ( responseText.responseJSON) {
                    for (var  error in responseText.responseJSON.errors) {
                        var msg = responseText.responseJSON.errors[error];
                        errorMessage += '<div class="message--error -light">' + msg + '</div>';
                    }
                }
                if ( responseText.responseJSON) {
                    for (var field in responseText.responseJSON.children) {
                        var child = responseText.responseJSON.children[field];
                        if (child.errors) {
                            var len = child.errors.length,
                                $field = $form.find('[name="' + field + '"]').closest('.form-field');
                            for (var i = 0; i < len; i++) {
                                $field.append('<div class="message--error -light">' + child.errors[i] + '</div>')
                            }
                        } else if (child.children) {
                            let subchild;
                            for (subchild in child.children) {
                                if (child.children[subchild].errors) {
                                    var len = child.children[subchild].errors.length,
                                        $field = $form.find('[name="' + field + '[' + subchild + ']"]').closest('.form-field');
                                    for (var i = 0; i < len; i++) {
                                        $field.append('<div class="message--error -light">' + child.children[subchild].errors[i] + '</div>')
                                        $field.addClass('has-error');
                                    }
                                }
                            }
                        }
                    }
                }

                $($form).prepend('<div class="message--error">' + errorMessage + '</div>');

                var actionBtn = $form.find('[type="submit"]').closest('.form-action');
                $('<div class="message--error">' + errorMessage + '</div>').insertBefore(actionBtn);

            }
        });


        /**
         * Highlight slider
         */
        var $highlightSlider = $('.js-highlight-slider');

        if ($highlightSlider.length && $highlightSlider.find('li').length > 1) {
            $('.highlight-slider__next').addClass('is-visible');
            $('.highlight-slider__prev').addClass('is-visible');

            $highlightSlider.cycle({
                slides: '> li',
                pager: '.highlight-slider__pager',
                pagerTemplate: '<span>&nbsp;</span>',
                next: '.highlight-slider__next',
                prev: '.highlight-slider__prev'
            });
        }


        $body.on('click', function () {
            if ($body.hasClass('has-search')) {
                $('.search-form__close').click();
            }

            if ($body.hasClass('has-main-menu')) {
                $('.main-nav-burger').click();
            }
        });

        $body.on('click', '.header', function (e) {
            e.stopPropagation();
        });

        // /* close submenu on click outside */
        // $body.on('click', function () {
        //     $('.main-nav .js-aria-control[aria-expanded="true"]').attr('aria-expanded', false);
        //     $('.submenu[aria-hidden="false"]').attr('aria-hidden', true);
        // });
        //
        // $body.on('click', '.main-nav', function (e) {
        //     e.stopPropagation();
        // });


    });


    $(window).load(function () {
        var _restaurantTypeParameter = getParameterByName('type'),
            _restaurantHoursParameter = getParameterByName('hours'),
            _movieHoursSelect = $('.js-filter-movie-hours');

        heroBannerVideo();


        /* Equal height */
        $('.js-sameheight').matchHeight();

        /* If param, update restaurant type filter */
        if (_restaurantTypeParameter !== null) {
            $('.restaurants-search__type').val(_restaurantTypeParameter).change();
        }

        /* If param, update restaurant hours filter */
        if (_restaurantHoursParameter !== null) {
            $('.restaurants-search__hours').val(_restaurantHoursParameter).change();
        }

        /* Trigger change on movie list hours */
        if (_movieHoursSelect.length) {
            _movieHoursSelect.change();
        }
    });


    $(window).resize(function () {
        if (window.innerWidth != windowWidth) {
            checkVisibility('main-nav', mqBreakpoints.desktop);
        }
    });


})
(jQuery);
