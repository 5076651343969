$(document).ready(function() {
    if ($('body').hasClass('modern_dynamic')) {
        $(window).scroll(function () {
            var height = $(window).scrollTop();

            if (height > 100) {
                $('body').removeClass('modern_dynamic__enabled');
            } else {
                $('body').addClass('modern_dynamic__enabled');
            }
        });

        $(window).trigger('scroll');
    }
});
