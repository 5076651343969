(function($){
    $(document).ready(function() {
        $('#hero-slideshow').each(function () {
            if (!$(this).hasClass('slick-initialized')) {
                $(this).slick({
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    variableWidth: false,
                    focusOnSelect: true,
                    slidesToShow: 1,
                    responsive: [
                        {
                            breakpoint: 990,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ],
                });
            }
        });
    });
})(jQuery);
