(function($){
    var Confirmation = function (element) {
        var self = this;

        self.element = element;

        self.options = {
            display: 'box', // available values : inline ...
            auto_remove: true,
            auto_remove_delay: 2000, // milliseconds. Only if the auto remove option is enabled
            type: 'success', // available values : success, warn, error ...
            pictogram: '', // Used as a little picto. Available values : leave empty or image url
            illustration: '', // Used as a big image. Available values : leave empty or image url
            dom_parent: self.element,
            background_effect: 'none', // available values : blur, none
            order: 'before', // available values : before, after,
            close_btn_label: '', // the label of the close button, if leaved empty, no btn is added
            close_redirect: '' // if specified, a redirection is processed after the confirmation is dismissed
        };

        self.init = function(message, options) {
            self.options = $.extend(self.options, options);
            self.message = message;

            var container = self.getContainer();
            var content = self.getContent(container);

            self.getImageWrapper(content);
            self.getMessageWrapper(content, message);
            self.getCloseLink(content, 'OK');
            var timeBar = self.getTimeBar(content);

            if (self.options.order === 'before') {
                container.prependTo(self.options.dom_parent);
            } else {
                container.appendTo(self.options.dom_parent);
            }

            self.show(container, timeBar);
            self.hide(container);
        };

        self.hide = function(container) {
            container.on('scc_confirmation.close', function () {
                if (self.options.close_redirect) {
                    window.location.replace(self.options.close_redirect);
                    return;
                }

                $(this).animate({
                    top: '-5rem',
                    opacity: 0
                }, 200, function() {
                    if (self.options.background_effect === 'blur') {
                        $('main.main, header.header, footer.footer').css('filter', 'blur(0)');
                    }

                    $(this).remove();
                });
            });
        };

        self.show = function(container, timeBar) {
            if (self.options.background_effect === 'blur') {
                $('main.main, header.header, footer.footer').css('filter', 'blur(25px)');
            }

            container.animate({
                top: 0,
                opacity: 1
            }, 200, function () {
                if (self.options.auto_remove_delay > 0) {
                    timeBar.animate({
                        width: 'toggle',
                    }, self.options.auto_remove_delay, function () {
                        $(this).trigger('scc_confirmation.close');
                    });
                }
            });
        };

        self.getContainer = function() {
            var container = $('<div></div>')
                .addClass('scc-confirmation')
                .addClass('scc-confirmation-' + self.options.display)
                .addClass('scc-confirmation-' + self.options.type);

            container.on('click', function(event) {
                if (
                    event.target.className.includes('scc-confirmation__content')
                    || event.target.closest('.scc-confirmation__content')
                ) {
                    return;
                }

                $(this).trigger('scc_confirmation.close');
            });

            return container;
        };

        self.getCloseLink = function(container, label) {
            if (self.options.close_btn_label !== '') {
                var closeArea = $('<div></div>').addClass('scc-confirmation__close').appendTo(container);
                var closeLink = $('<div></div>').addClass('scc-confirmation__close-link').html(label).appendTo(closeArea);

                closeLink.on('click', function() {
                    $(this).trigger('scc_confirmation.close');
                });

                return closeArea;
            }
        };

        self.getTimeBar = function (container) {
            if (self.options.auto_remove_delay > 0) {
                var timeBarContainer = $('<div></div>').addClass('scc-confirmation__time-bar');
                timeBarContainer.appendTo(container);

                return timeBarContainer;
            }

            return null;
        };

        self.getContent = function(container) {
            var content = $('<div></div>').addClass('scc-confirmation__content');
            content.appendTo(container);

            return content;
        };

        self.getMessageWrapper = function(container, message) {
            var messageWrapper = $('<div></div>').addClass('scc-confirmation__message').html(message);
            messageWrapper.appendTo(container);

            return messageWrapper;
        };

        self.getImageWrapper = function(container) {
            if (self.options.pictogram) {
                var pictogramWrapper = $('<div></div>').addClass('scc-confirmation__pictogram');
                var img = $('<img />').attr('src', self.options.pictogram).attr('alt', '');
                pictogramWrapper.append(img).appendTo(container);

                return pictogramWrapper;
            }

            if (self.options.illustration) {
                var illustrationWrapper = $('<div></div>').addClass('scc-confirmation__illustration');
                var img = $('<img />').attr('src', self.options.illustration).attr('alt', '');
                illustrationWrapper.append(img).appendTo(container);

                return illustrationWrapper;
            }

            return null;
        };
    };

    $.fn.sccConfirmation = function(message, options) {
        return new Confirmation($(this)).init(message, options);
    };

    $.fn.sccSuccessConfirmation = function(message, options) {
        options = $.extend(options, { type: 'success' });

        return $(this).sccConfirmation(message, options);
    };

    $.fn.sccErrorConfirmation = function(message, options) {
        options = $.extend(options, { type: 'error' });

        return $(this).sccConfirmation(message, options);
    };

    $.fn.sccWarningConfirmation = function(message, options) {
        options = $.extend(options, { type: 'warn' });

        return $(this).sccConfirmation(message, options);
    };
})(jQuery);
