/**
 * --------------------------------------------------------------------
 * jQuery underPanel
 * Open a panel under the content like Google Images
 * --------------------------------------------------------------------
 */
(function($){
    $.fn.underPanel=function(options){
        // variables used in the plugin
        var defaults = {
                "panelSelector": this.selector,
                "trigger": ".js-underpanel",
                "closeTrigger": ".js-underpanel-close"
            },
            parameters = $.extend(defaults, options),
            $_that = this;




        /**
        * Function that calculate element position
         * http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
        */
        var getParameterByName = function (name) {
            name = name.replace(/[\[\]]/g, "\\$&");

            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                url = window.location.href,
                results = regex.exec(url);

            if (!results) return null;
            if (!results[2]) return '';

            return decodeURIComponent(results[2].replace(/\+/g, " "));
        };




        /**
         * Smoothscroll
         */
        var smoothScroll = function( position ){
            $('html, body').animate({
                scrollTop: position
            }, '350');
        };




        /**
        * Calculate element position
        */
        var calculatePosition = function(el , panel, parent){
            var $_shopsCat = $('.shop-categories-list'),
                shopsCatTop = $_shopsCat.offset().top,
                parentHeight = parent.height(),
                parentTop = parent.offset().top,
                panelTop = (parentTop - shopsCatTop) + parentHeight + 40 + 'px',
                panelHeight = panel.outerHeight(),
                parentMarginBottom = panelHeight + 80 + 'px';

            return {
                panelTop: panelTop,
                parentMarginBottom: parentMarginBottom
            }
        };



        /**
         * Close opened panel
         */
        var closePanel = function(panelId) {
            var $_this = $(parameters.trigger + '[aria-controls="'+ panelId +'"]'),
                $_panel = $('#' + $_this.attr('aria-controls')),
                $_parent = $_this.parents('.img-card');

            $_parent.removeClass('-has-panel');
            $_parent.removeAttr('style');

            $_panel.attr('aria-hidden', true);
            $_this.attr('aria-expanded', false);
            $_panel.trigger('underpanel:close');
        };



        /**
         * Open a panel
         */
        var openPanel = function($_this) {
            var $_panel = $('#' + $_this.attr('aria-controls')),
                $_parent = $_this.parents('.img-card'),
                positionValues;

            positionValues = calculatePosition($_this, $_panel, $_parent);

            /* Calculate the position of the element */
            $_panel.css('top', positionValues.panelTop);

            /* Add class to the parent */
            $_parent.addClass('-has-panel');

            /* Add margin bottom to the parent to push next element after the panel */
            $_parent.css('margin-bottom', positionValues.parentMarginBottom);

            /* Show panel for ARIA */
            $_panel.attr('aria-hidden', false);
            $_this.attr('aria-expanded', true);
            $_panel.trigger('underpanel:opened');
        };




        $('body').on('click', parameters.trigger, function(e){
            e.preventDefault();

            var $_this = $(this),
                $_panel = $('#' + $_this.attr('aria-controls')),
                _openPanelID = $(parameters.trigger + '[aria-expanded="true"]').attr('aria-controls'),
                $_openPanel = $('#' + _openPanelID),
                state = $_this.attr('aria-expanded') !== 'false',
                timeoutDuration = 0;

            /*
            * If another panel is open, close it and set duration to 350ms
            */
            closePanel(_openPanelID);
            if( $_openPanel.length && $_openPanel.get(0) !== $_panel.get(0)){
                timeoutDuration = 400;
            }

            /* setTimeout because of animation */
            if( !state ) {
                window.setTimeout(function() {
                    openPanel($_this);
                }, timeoutDuration);
            }
        }).on('click', parameters.closeTrigger, function(e){
            e.preventDefault();

            /* Close panel when closeTrigger is clicked */
            var $_this = $(this),
                _panelID = $_this.parents(parameters.panelSelector).attr('id');
            closePanel(_panelID);
        });




        /**
         * If there's a parameter, open and scroll to the category
         */
        $(window).on('load', function(){
            var _parameterCat = getParameterByName('category'),
                $_catTrigger = $(parameters.trigger + '[aria-controls="'+ _parameterCat +'"],' +
                    parameters.trigger + '[href="'+window.location.pathname+'"]'),
                $_anchorParent = $_catTrigger.parents('.img-card');

            if( $_catTrigger.length ){
                $_catTrigger.click();
                smoothScroll( $_anchorParent.offset().top - 120 );  /* 100 for fixed header space */
            }
        });


        // Return the element for jQuery chaining
        return $_that;
    };
})(jQuery);