(function($){
    $(document).ready(function() {
        $('.slick').each(function () {
            if (!$(this).hasClass('slick-initialized')) {
                $(this).slick({
                    appendArrows: $('.slick--nav'),
                    variableWidth: false,
                    focusOnSelect: true,
                    easing: 'swing',
                    responsive: [
                        {
                            breakpoint: 990,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ],
                });
            }
        });
    });
})(jQuery);
