(function($) {
    var Tracking = function(element) {
        var reconnectFrequencySeconds = 1;
        var self = this;

        self.element = element;

        self.options = {
            mall: null,
            socket_service: 'https://mercure.mde-momento.com',
            use_cookie: true,
            cookie_name: 'sccd_realtime_tracker',
            on_process: function(data) {},
        };

        self.init = function(options) {
            self.options = $.extend(self.options, options);

            if (null === self.options.mall || '' === self.options.mall) {
                console.error('The mall have to be defined.');
                return;
            }

            if (self.options.use_cookie) {
                var data = self.getCookie(self.options.cookie_name);
                if (data) {
                    self.options.on_process(JSON.parse(data));
                }
            }

            self.setupEventSource();
        };

        // check the support of JS by the current browser
        self.isFunction = function (functionToCheck) {
            return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
        }

        self.debounce = function (func, wait) {
            var timeout;
            var waitFunc;

            return function () {
                if (self.isFunction(wait)) {
                    waitFunc = wait;
                } else {
                    waitFunc = function () {
                        return wait
                    };
                }

                var context = this, args = arguments;
                var later = function () {
                    timeout = null;
                    func.apply(context, args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, waitFunc());
            };
        }

        self.reconnect = function () {
            setupEventSource();
            // Double every attempt to avoid overwhelming server
            reconnectFrequencySeconds *= 2;
            // Max out at ~1 minute as a compromise between user experience and server load
            if (reconnectFrequencySeconds >= 64) {
                reconnectFrequencySeconds = 64;
            }
        };

        self.wait = function () {
            return reconnectFrequencySeconds * 1000
        };

        self.setCookie = function (cname, cvalue, exminutes) {
            var d = new Date();
            d.setTime(d.getTime() + (exminutes*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax";
        }

        self.getCookie = function (cname) {
            var name = cname + '=';
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }

            return '';
        }

        self.setupEventSource = function () {
            const evtSource = new EventSource(self.options.socket_service + '/.well-known/mercure?topic=' + encodeURIComponent(self.options.socket_service + '/topic/pass/' + self.options.mall));

            evtSource.onmessage = function (e) {
                var data = JSON.parse(e.data);

                if (self.options.use_cookie) {
                    self.setCookie(self.options.cookie_name, JSON.stringify(data), 10);
                }

                self.options.on_process(data);
            };

            evtSource.onopen = function () {
                // Reset reconnect frequency upon successful connection
                reconnectFrequencySeconds = 1;
            };

            evtSource.onerror = function () {
                evtSource.close();
                self.debounce(self.reconnect, self.wait);
            };
        }
    }

    $.fn.realtimeTrack = function(options) {
        return new Tracking($(this)).init(options);
    };
})(jQuery);
