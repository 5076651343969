function initialize() {
    var myLatlng = new google.maps.LatLng(mallAddress.lat, mallAddress.lng);
    var mapOptions = {
        zoom: 15,
        center: myLatlng
    };

    var map = new google.maps.Map(document.getElementById('mall-map'), mapOptions);

    /* Add marker on the map */
    var coords = new google.maps.LatLng(mallAddress.lat, mallAddress.lng);
    var markerOptions = {
        position: coords,
        map: map,
        icon: mallAddress.img,
        lat: mallAddress.lat,
        lng: mallAddress.lng
    };

    var marker = new google.maps.Marker(markerOptions);
}

function loadGmaps() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
        '&key=AIzaSyA2kNyreJsLE-PBV4LNwv2fXGxCmGdIpLQ&callback=initialize';
    document.body.appendChild(script);
}

if( $('#mall-map').length > 0 ){
    window.onload = loadGmaps;
}
