$(document).ready(function(){
    $('.testimonial__slick').slick({
        autoplay: true,
        arrows: false,
        dots: true,
        slidesToShow: 1,
        infinite: true,
        dotsClass: 'testimonial__dots',
        centerMode: true,
        variableWidth: true,
        focusOnSelect: true,
        easing: 'swing',
        autoplaySpeed: 4500,
    });
});