var moment = require('moment');

global.Analytics = function($) {

    // private functions & variables
    var
        tracker,
        commonData
    ;

    var setTracker = function(t) {
        tracker = t;
    };

    var setCommonData = function() {
        commonData = {
            'language': document.documentElement.lang,
            'page': (window.location.href.toString().split(window.location.host))[1],
            'clientID': tracker.get('clientId'),
            'userID': tracker.get('userId'),
            'date': moment().format(),
            'ua': navigator.userAgent,
            'sr': tracker.get('screenResolution'),
            'vp': tracker.get('viewportSize'),
            'referrer': window.document.referrer
        };
    };

    var attachHandler = function() {
        $('body').on('click', 'a, button[type="button"]', {'type': 'event', 'action': 'click'}, sendAnalytics);
        $('body').on('click', '#tarteaucitronRoot button', {'type': 'tac', 'action': 'click'}, sendAnalytics);
        $('body').on('submit', 'form', {'type': 'event', 'action': 'submit'}, sendAnalytics);
        $('body').on('underpanel:opened', '.underpanel', function() {
            sendReferences($(this).find('[data-reference]'));
        });
    };

    var sendPageView = function() {
        var e = $.Event('pageView', {
            'data' : {
                'type': 'pageView',
            }
        });
        sendAnalytics(e);
    };

    var sendAnalytics = function (event) {
        $.extend(event.data, commonData);
        switch(event.data.type) {

            case 'pageView':
                let ab = [];
                $('body').data('ab').split(';').forEach(function (element) {
                    if (element ) {
                        ab.push({'reference': element.split('|')[0], 'value': element.split('|')[1]});
                    }
                });

                let features = {
                    'ga': getCookie('_ga') !== '' ,
                    'consent': getCookie('consent') || '<none>'
                };

                $.extend(event.data, {
                    'ab': ab,
                    'features': features
                });
                break;
                
            case 'event':
                $target = $(event.target);

                if ($target.hasClass('js-do-not-track') || $target.hasClass('slick-arrow')) {
                    return;
                }

                var async = false;
                if (typeof $target.attr('data-async') !== 'undefined' ) {
                    async = ($target.attr('data-async') === true || $target.attr('data-async') === 'true');
                }

                $.extend(event.data, {
                    'parameters': {
                        'reference': $target.closest("[data-reference]").data('reference')||'<none>',
                        'position': $target.closest("[data-position]").data('position')||'0',
                        'blockname': $target.closest("[data-blockname]").data('blockname')||'<none>',
                        'region': $target.closest("[data-region]").data('region')||'<none>',
                        'block': $target.closest("[data-block]").data('block')||'<none>',
                        'section': $target.closest("[data-section]").data('section')||'<none>',
                        'page' : $('body').data('page')||'<none>',
                        'url': $target.closest('a').attr('href')||'<none>'
                    }
                });
                break;

            case 'tac':
                $target = $(event.target);

                var async = false;
                if (typeof $target.attr('data-async') !== 'undefined' ) {
                    async = ($target.attr('data-async') === true || $target.attr('data-async') === 'true');
                }

                $.extend(event.data, {
                   'parameters': {
                        'reference': '<none>',
                        'position': '0',
                        'blockname': 'tac',
                        'region': 'tac',
                        'block': 'tac',
                        'section': 'tac',
                        'page' : $('body').data('page')||'<none>',
                        'url': $target.closest('button').attr('id')||'<none>'
                    }
                });
                break;
        }

        $.ajax({
            'url': Routing.generate('analytics'),
            'async': async,
            'data': event.data,
            'method': 'POST'
        }, event.data);
    };

    var sendReferences = function(elts) {
        var references = [];
        elts.each(function (i,elt) {
            var that = $(elt);
            var tracked = that.data('tracked') || false;

            if (that.closest('.underpanel[aria-hidden="true"]').length === 0) {
                if (that.is(':visible')/* && !tracked*/) {
                    references.push(that.attr('data-reference'));
                    that.data('tracked', true);
                }
            }
        });
        $.ajax({
            'url': Routing.generate('analytics-references'),
            'data': {"references": references, "page": $('body').attr('data-path')},
            'method': 'POST'
        });
    };

    var setCookie = function(cname) {
        var unique = uniqueID();
        window.document.cookie = cname + "=" + unique + '; path=/';
        return unique;
    };

    var getCookie = function getCookie(cname) {
        var name = cname + "=";
        var cArr = window.document.cookie.split(';');

        for (var i = 0; i < cArr.length; i++) {
            var c = cArr[i].trim();

            if (c.indexOf(name) == 0)
                return c.substring(name.length, c.length);
        }

        return "";
    };

    var uniqueID = function () {
        function chr4() {
            return Math.random().toString(16).slice(-4);
        }

        return chr4() + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() + chr4() + chr4();
    };

    // public functions
    return {

        //main function
        init: function () {

            var t = getCookie('analytics');
            t = t !== '' ? t : setCookie('analytics');

            var tracker = {
                clientId: t,
                userId: t,
                screenResolution:  window.screen.width + 'x' + window.screen.height,
                viewportSize:  window.innerWidth + 'x' + window.innerHeight,
                get: function (key) {
                    return this[key] || false;
                },
            };

            setTracker(tracker);
            setCommonData();
            attachHandler();
            sendPageView();
            sendReferences($(':not(.underpanel[aria-hidden="true"]) [data-reference]'));
        }

    };

}(jQuery);

