import { getUrlParameter } from '../utils';

(function($){
    const select = document.getElementById('contact-form[store]');
    if (select) {
        $(select).select2({
            placeholder: select.getAttribute('data-placeholder'),
        });
    }

    var AutoFillForm = function (element) {
        var self = this;

        self.init = function () {

            // start to auto fill
            var formFields = element.find(':input');

            formFields.each(function (index, formField) {
                var fieldId = $(formField).attr('id');
                var fieldType = $(formField).attr('type');
                var value = getUrlParameter(fieldId);

                if (value) {
                    if (fieldType === 'radio') {
                        $(formField).prop('checked', true);
                    } else {
                        $(formField).val(value);
                    }
                }
            });
        };
    };

    $.fn.autoFillForm = function () {
        return new AutoFillForm($(this)).init();
    };
})(jQuery);
