var SmartBanner = require('smart-app-banner');

(function($){
    var SmartAppBanner = function (options) {
        var self = this;

        self.options = {
            daysHidden: options.daysHidden ? options.daysHidden : 15,   // days to hide banner after close button is clicked (defaults to 15)
            daysReminder: options.daysHidden ? options.daysHidden : 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
            title: options.title ? options.title : '',
            author: options.author ? options.author : '',
            button: options.button ? options.button : 'VIEW',
            store: {
                ios: 'App Store',
                android: 'Google Play',
            },
            price: {
                ios: '0€',
                android: '0€',
            }
            // , theme: 'android' // put platform type ('ios', 'android', etc.) here to force single theme on all device
            // , icon: '' // full path to icon image if not using website icon image
            // , force: 'android' // Uncomment for platform emulation
        };

        self.init = function() {
            self.banner = new SmartBanner(self.options);

            $('.smartbanner').attr('data-section', 'smartbanner');
            $('.smartbanner').attr('data-blockname', 'smartbanner');
            $('.smartbanner.smartbanner-android').attr('data-region', 'smartbanner-android');
            $('.smartbanner.smartbanner-android').attr('data-block', 'smartbanner-android');
            $('.smartbanner.smartbanner-ios').attr('data-region', 'smartbanner-ios');
            $('.smartbanner.smartbanner-ios').attr('data-block', 'smartbanner-ios');

            return self.banner;
        };
    };

    $.fn.smartAppBanner = function(options) {
        return new SmartAppBanner(options).init();
    };
})(jQuery);
