(function($) {
    $(document).ready(function() {
        /** Grouped filters */
        var groupsFilterElmt = $('.js-listing-group-filters'),
            filterCounter = $('#js-listing-group-filters-counter'),
            activeFilters = 0,
            criteria = {};

        groupsFilterElmt.each(function() {
            var targetSelector = $(this).attr('data-filter-target'),
                groupFilterElmt = $(this);

            if (!targetSelector) {
                return;
            }

            criteria[targetSelector] = {};

            /** Handle filters (disable if no item in the target selector and attach action if at least one item in the list) */
            function handleFilters(context, filterValue) {
                activeFilters = 0;

                var group = groupFilterElmt;

                if (context && filterValue && filterValue !== 'all') {
                    group = groupFilterElmt.find('[data-filter-group-item]')
                        .not('[data-filter-group-item="' + context + '"]');
                }

                group.find('button').each(function () {
                    var filter = $(this).attr('data-filter'),
                        isDisablingAllowed = true;

                    if (typeof $(this).attr('data-allow-disable') !== 'undefined') {
                        isDisablingAllowed = ($(this).attr('data-allow-disable') === true || $(this).attr('data-allow-disable') === 'true');
                    }

                    if (isDisablingAllowed && $(targetSelector + '[aria-hidden=false][data-filter*="' + filter + '"]').length === 0) {
                        $(this).addClass('disabled');
                    } else {
                        $(this).removeClass('disabled');
                    }
                });

                groupFilterElmt.find('button').each(function () {
                    var filter = $(this).attr('data-filter');

                    if ($(this).hasClass('active') && filter !== 'all' && filter.substr(filter.length - 4) !== '-all') {
                        activeFilters++;
                    }
                });

                if (filterCounter) {
                    filterCounter.html(activeFilters);

                    if (activeFilters > 0) {
                        filterCounter.show();
                    } else {
                        filterCounter.hide();
                    }
                }
            }

            groupFilterElmt.find('button').on('click', function() {
                if ($(this).hasClass('disabled')) {
                    return;
                }

                var item = $(this).closest('[data-filter-group-item]'),
                    filter = $(this).attr('data-filter');

                if (!item) {
                    return;
                }

                var itemName = item.attr('data-filter-group-item');

                if (!itemName) {
                    return;
                }

                groupFilterElmt.find('button').removeClass('active');

                var containsSelector = '';
                if (filter === 'all') {
                    criteria[targetSelector] = {};
                } else {
                    criteria[targetSelector][itemName] = filter;

                    $.each(criteria[targetSelector], function(itemName, criterium) {
                        containsSelector = containsSelector + '[data-filter*="' + criterium + '"]';
                        groupFilterElmt.find('[data-filter-group-item="' + itemName + '"] button[data-filter="' + criterium + '"]').addClass('active');
                    });
                }

                $(targetSelector).attr('aria-hidden', true).fadeOut();
                $(targetSelector + containsSelector).attr('aria-hidden', false).fadeIn();

                handleFilters(itemName, filter);
            });

            handleFilters();
        });
    });
})(jQuery);
