/**********
 * SASS
 **********/
import 'smart-app-banner/dist/smart-app-banner.css';
import '../scss/app.scss';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';


/**********
 * JS @todo => The goal is to remove completely the "vendor" folder which should not exists
 **********/

// Popups
require('magnific-popup');

// Masonry layout (e:g : blog pages such as Plaza Rio 2 blog)
require('masonry-layout');

// Carousels
require('slick-carousel');

// Utils
require('jquery-match-height'); // Used to handle the forced height of cards with JS for old browsers

// TAC (RGPD)

// EventSource polyfill
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
const EventSource = NativeEventSource || EventSourcePolyfill;
global.EventSource =  NativeEventSource || EventSourcePolyfill;

/**********
 * Legacy
 **********/
require('./main');

require('./vendor/jquery.tabs');
require('./vendor/jquery.form.min');
require('./vendor/jcycle2'); // @todo Check if always in use and if not, remove it
require('./vendor/jquery.autocomplete');
require('./vendor/pushmenu');
require('select2');

/**********
 * Components
 **********/
require('./components/preview');
require('./components/forms');
require('./components/testimonial');
require('./components/confirmation');
require('./components/slick');
require('./components/hero');
require('./components/aside_scroll');
require('./components/filter');
require('./components/pushsidebar');
require('./components/switcher');
// require('./components/form_persist');
require('./components/modern_dynamic');
require('./components/realtime_tracker');
require('./analytics');
require('./underpanel');
require('./map');
require('./smart-app-banner');
require('./components/menu');

