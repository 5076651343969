function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
}

window.getUrlParameter = getUrlParameter;

function pad(n) {
    return n < 10 ? '0' + n : n;
}

function ISODateString(d) {
    return d.getFullYear() + '-'
        + pad(d.getMonth() + 1) + '-'
        + pad(d.getDate()) + 'T'
        + pad(d.getHours()) + ':'
        + pad(d.getMinutes()) + ':'
        + pad(d.getSeconds()) + 'Z'
}

function parseDate(d) {
    var parts = d.split(/\D/);
    return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], 0);
}

export { getUrlParameter, ISODateString, parseDate };
