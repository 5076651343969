(function($){
    $(document).ready(function() {
        if ($(window).width() > 770 && $('.aside__wrapper').hasClass('--scroll')) {
            setTimeout(function() {
                var position = $(window).scrollTop(),
                    asideWrapperEl = $('.aside__wrapper'),
                    asideEl = $('.aside'),
                    asideContentEl = $('.aside__content'),
                    headerHeight = $('header.header').outerHeight(true),
                    bottomGutter = 25;

                var viewportHeight = $(window).height();
                var wrapperHeight = asideWrapperEl.outerHeight(true);
                var asideHeight = asideContentEl.outerHeight(true);
                var asideBottom = asideContentEl.offset().top + asideHeight;
                var bottomAttachPoint = asideBottom - viewportHeight + bottomGutter;

                asideEl.css('max-height ', wrapperHeight);

                $(window).scroll(function() {
                    var scroll = $(window).scrollTop(),
                        height = viewportHeight + scroll - headerHeight - bottomGutter;

                    if(scroll > position) {
                        if (bottomAttachPoint <= scroll) {
                            asideEl.css('min-height', Math.min(height, wrapperHeight));
                            asideContentEl.removeAttr('style').css('bottom', 0);
                        } else {
                            asideEl.css('min-height', 1);
                            asideContentEl.removeAttr('style').css('top', 0);
                        }
                    } else {
                        asideEl.css('min-height', 1);

                        if (bottomAttachPoint <= scroll) {
                            asideEl.css('margin-top', Math.min(scroll, wrapperHeight - asideHeight));
                            asideContentEl.removeAttr('style');
                        } else {
                            asideEl.css('margin-top', 0);
                            asideContentEl.removeAttr('style');
                        }
                    }

                    position = scroll;
                });
            }, 250);
        }
    });
})(jQuery);
