(function($) {
    $.fn.switchClick = function(selector, options) {
        var _ctrl = $(this),
            handledArea = $(selector);

        // Handle the click
        _ctrl.on('click', function() {
            var switchCriteria = $(this).attr('data-switch') || null;

            if (switchCriteria) {
                _ctrl.removeClass('active');
                $(this).addClass('active');

                handledArea.find('[data-switched]').hide(0, function() {
                    handledArea.find('[data-switched*="' + switchCriteria + '"]').show(0);
                });

            }
        });

        // Auto select before any click
        _ctrl.each(function() {
            if ($(this).hasClass('active')) {
                $(this).trigger('click');
            }
        });
    };
})(jQuery);
