import { getUrlParameter } from '../utils';

(function($){
    $(document).ready(function() {
        var preview = getUrlParameter('preview');

        if (preview) {
            $(document).sccWarningConfirmation('<b>PREVIEW MODE</b>', {
                dom_parent: $('body'),
                background_effect: 'blur',
                illustration: '/build/images/undraw/preview01_thumb.png',
                auto_remove_delay: 1500,
            });
        }
    });
})(jQuery);
