document.addEventListener('DOMContentLoaded', () => {
    const submenuTitles = document.querySelectorAll('.main-nav__menu > li:has(.menu_level_1)');

    function closeDropdowns () {
        Array.from(submenuTitles).map(item => {
            item.classList.remove('active');
        })
    }

    Array.from(submenuTitles).map(submenuTitle => {
        submenuTitle.addEventListener('click', (e) => {
            e.stopPropagation();
            if (submenuTitle.firstElementChild === e.target) {
                e.preventDefault();
            }
            if (!submenuTitle.classList.contains('active')) {
                closeDropdowns()
                submenuTitle.classList.add('active');
            } else {
                submenuTitle.classList.remove('active');
            }
        })
    })

    document.body.addEventListener('click', () => {
        closeDropdowns();
    })
})